import React from 'react'

const AssignedLimits = ({limit}) => {
  return (
    <div>
        <h1 className="text-2xl font-medium flex justify-center pb-5"> Assigned Limits </h1>
        <table className="w-full text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50"></thead>
                <tbody>
                    <tr className="bg-white border-y">
                        <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                            Monthly :<span className="ml-10">{limit?.monthly || "0"}</span>
                        </th>
                        <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                        Available :<span className="ml-10">{limit?.monthlyAvailable || "0"}</span>
                        </th>
                    </tr>
                    <tr className="bg-white border-y">
                        <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                        &nbsp;&nbsp;Inflight : <span className="ml-9">{limit?.inflight || "0"}</span>
                        </th>
                        <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                        Available :<span className="ml-10">{limit?.inflightAvailable || "0"}</span>
                        </th>
                    </tr>
                </tbody>
        </table>
    </div>
  )
}

export default AssignedLimits
