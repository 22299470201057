import React, { useState } from 'react'
import Spinner from '../../Spinner';
import Cookies from 'js-cookie'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
const LOGIN_URL = '/auth';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { setAuth } = useAuth(); 
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/ticket";
    const [error, setError] = useState(false)

    const handleFailure = () => {
      setError("Login failed")
    };
    
    
    const handleLogin = async (googleData) => {
      setIsLoading(true)
        try{
            const response = await axios.post(
                LOGIN_URL,JSON.stringify({
                    gydaToken: googleData.credential,
                    }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                
                                withCredentials: true
                            })
            if(response?.data?.msg) {
              setIsLoading(false)
              return setError(response?.data?.msg) 
            }
            const accessToken = response?.data?.accessToken;
            const isAdmin = response?.data?.isAdmin;
            const email = response?.data?.email;
            const username = response?.data?.username;
            const userLimits = response?.data?.userLimits;
            const title = response?.data?.title;
            Cookies.set('gjwt', googleData.credential)
            setAuth({accessToken, isAdmin, email, username, userLimits, title})
            navigate(from, { replace: true });  
        }catch(err){
            setIsLoading(false)
            return setError("Authentocation failed") 

        }
    }

  return (
    <div>
      <div className='max-w-6xl px-10 container mx-auto pt-20'>
        <div className="overflow-x-auto relative sm:rounded-lg flex justify-center w-full">
            <div className="max-w-sm shadow-md">
                    <img className="rounded-t-lg cursor-pointer" src="assets/logo.png" alt="" />

                <div className="p-5 flex justify-center">
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                        text = "signin_with"
                        onSuccess={handleLogin}
                        onError={handleFailure}
                        target="_self"
                        />
                    </GoogleOAuthProvider>
                </div>

                <div className="p-2 flex justify-center">
                    {isLoading ? (
                      <Spinner />
                    ):
                    (error !== false && (
                      <p className='text-sm text-red-600'>{error}</p>
                    ))}
                </div>
            </div>
        </div>
      </div>
    </div>
   
  )
}

export default Login